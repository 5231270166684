import React, { lazy, useState, useLayoutEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { LoginSharedLayout } from "./components/Login";
import { LoginWithOTP, VerifyOTP } from "./pages/Login";
import { SharedLayout } from "./components/Global";
import { ManageOrders, OrderDetails } from "./pages/Orders";
import { useSelector } from "react-redux";
import { onMessageListener } from "./utils/fcm";

const Settings = lazy(() => import("./pages/Settings/Settings"));

const KEY = process.env.REACT_APP_2FACTOR_API_KEY;
export const API = `https://2factor.in/API/V1/${KEY}/SMS`;

function App() {
  const location = useLocation().pathname;
  const navigate = useNavigate();

  const user = useSelector((state) =>
    state?.users?.find(
      (user) => user.phone === localStorage.getItem("phoneNumber")
    )
  );

  useLayoutEffect(() => {
    if (["/", "/verify"].includes(location)) {
      return;
    } else if (!user?.roles?.includes("Kitchen")) {
      toast.error("Kitchen-Handler Login Required");
      return navigate("/");
    }
    onMessageListener();
  }, [location, navigate, user]);

  const [theme, setTheme] = useState(null);

  useLayoutEffect(() => {
    // checks system theme
    if (window.matchMedia("(prefers-color-scheme:dark)").matches) {
      setTheme("dark");
      localStorage.setItem("theme", "dark");
    } else {
      setTheme("light");
      localStorage.setItem("theme", "light");
    }
    // Disable Right Click & Long Press
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault();
    });
  }, []);

  useLayoutEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);
  return (
    <div className="relative w-screen h-screen flex flex-col bg-grey-light dark:bg-grey-dark font-inter text-grey-dark dark:text-grey-light select-none">
      <Toaster toastOptions={{ duration: 3500 }} />
      <Routes>
        <Route path="/" element={<LoginSharedLayout />}>
          <Route index element={<LoginWithOTP />} />
          <Route path="verify" element={<VerifyOTP />} />
        </Route>
        <Route path="orders" element={<SharedLayout />}>
          <Route index element={<ManageOrders />} />
          <Route path="details/:orderID" element={<OrderDetails />} />
        </Route>
        <Route path="settings" element={<SharedLayout />}>
          <Route index element={<Settings />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
