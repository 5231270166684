import { toast } from "react-hot-toast";
import { firestore } from "./firebase.config";
import {
  collection,
  doc,
  getDocs,
  updateDoc,
  setDoc,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import { setOrders } from "../context/actions/orderActions";
import { setUsers } from "../context/actions/userActions";
import { sendOrderNotification } from "./fcm";
import { LINK, ROLE, STATUS } from "./constants";

// GET FIRESTORE DATA - users | orders
export const getFirestoreData = async (
  collectionName,
  setLoading,
  dispatch
) => {
  try {
    setLoading(true);
    const querySnapshot = await getDocs(collection(firestore, collectionName));
    var data = [];
    querySnapshot.forEach((doc) => data.push(doc.data()));
    switch (collectionName) {
      case "orders":
        dispatch(setOrders(data));
        break;
      case "users":
        dispatch(setUsers(data));
        break;
      default:
        toast.error("Implementation Failed");
        break;
    }
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  } finally {
    setLoading(false);
  }
};

// UPDATE FIRESTORE DATA - orders ONLY
export const updateFirestoreData = async (
  orderID,
  data,
  setLoading,
  dispatch
) => {
  try {
    setLoading(true);
    const orderRef = doc(firestore, "orders", orderID);
    await updateDoc(orderRef, { orderStatus: data });
    toast.success(`Order Status updated to ${data}`);
    console.log("refreshing data");
    getFirestoreData("orders", setLoading, dispatch);
  } catch (error) {
    console.log(error);
    toast.error(error.message);
  } finally {
    setLoading(false);
  }
};

// FILTER ORDER BY DATE
export const filterOrder = (orders, filter) => {
  const today = 24 * 60 * 60 * 1000;
  const filters = {
    All: 0,
    Today: today,
    Yesterday: 2 * today,
    PastWeek: 7 * today,
  };
  var orderFilter = [];
  orders.forEach((order) => {
    const orderDate = order.orderDate.split("/");
    const orderTime = order.orderTime.split(":");
    const orderDateTime = new Date(
      orderDate[2],
      orderDate[1] - 1,
      orderDate[0],
      orderTime[0],
      orderTime[1].slice(0, 2)
    ).getTime();
    const diff = Date.now() - orderDateTime;
    if (diff <= filters[filter]) orderFilter.push(order);
  });
  return orderFilter;
};

// ORDER STATUS FILTER
export const filterOrderStatus = (orders, status) => {
  const orderStatus = orders?.filter((order) =>
    status.includes(order.orderStatus)
  );
  return orderStatus;
};

// ORDER TYPE FILTER
export const filterOrderType = (orders, type) => {
  const orderType = orders?.filter(
    (order) => type === order.orderType //.split(" ").join("")
  );
  return orderType;
};

// // PAYMENT STATUS FILTER
// export const filterPaymentStatus = (orders, status) => {
//   const paymentStatus = orders?.filter((order) =>
//     status.includes(order.paymentStatus)
//   );
//   return paymentStatus;
// };

// // PAYMENT TYPE FILTER
// export const filterPaymentType = (orders, status) => {
//   const paymentStatus = orders?.filter((order) =>
//     status.includes(order.paymentMethod)
//   );
//   return paymentStatus;
// };

// // PAYMENT METHOD FILTER
// export const filterPaymentMethod = (orders, method) => {
//   const paymentStatus = orders?.filter(
//     (order) =>
//       order.paymentStatus === "Paid" && method.includes(order.paymentMethod)
//   );
//   return paymentStatus;
// };

// // USER ROLE FILTER
// export const filterUserRole = (users, role) => {
//   const userRole = users.filter((user) => user.roles.includes(role));
//   return userRole;
// };

// // FILTER ORDERS MADE BY USER
// export const filterUserOrders = (orders, userPhone, orderType) => {
//   const filteredOrders = orders?.filter(
//     (order) => order.userPhone === userPhone && order.orderType === orderType
//   );
//   return filteredOrders;
// };

//

const onError = (error) => {
  console.log(error);
  toast.error(error?.message);
};

export const setUserOnilne = async (user, isOnline, dispatch) => {
  try {
    if (!isOnline) dispatch(setOrders([]));
    const userRef = doc(firestore, "users", user.phone);
    await setDoc(userRef, { isOnline: { Kitchen: isOnline } }, { merge: true });
    const status = isOnline ? "Online" : "Offline";
    const message = isOnline
      ? `${user.name} is ${status}`
      : `${user.name} went ${status}`;
    isOnline ? toast.success(`${message}`) : toast.error(`${message}`);
    getTokensByRole(ROLE.Admin).then((tokens) => {
      if (tokens.length)
        sendOrderNotification(tokens, {
          title: `${ROLE.Kitchen} User is ${status}`,
          body: `${message}`,
        });
    });
  } catch (error) {
    onError(error);
  }
};

export const getCurrentUser = async () => {
  try {
    const userRef = doc(
      firestore,
      "users",
      localStorage.getItem("phoneNumber")
    );
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) return userSnap.data();
    console.log("User Does Not Exist");
    toast.error("User Does Not Exist");
  } catch (error) {
    onError(error);
  }
};

export const setNotificationToken = async (userID, notificationToken) => {
  try {
    const userRef = doc(firestore, "users", userID);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      const token = userSnap.data().notificationToken;
      // Check if token exists & Only update unique/new token
      if (token?.User === notificationToken) return;
      return await setDoc(
        userRef,
        {
          notificationToken: { Kitchen: notificationToken },
        },
        { merge: true }
      );
    }
    console.log("User Does Not Exist");
    toast.error("User Does Not Exist");
  } catch (error) {
    onError(error);
  }
};

export const getTokensByRole = async (role) => {
  try {
    const userRef = collection(firestore, "users");
    const q = query(userRef, where("roles", "array-contains", role));
    const querySnapshot = await getDocs(q);
    var notificationTokens = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      const notificationToken = data?.notificationToken;
      if (!notificationToken) return;
      const roleToken = notificationToken[role];
      if (!roleToken) return;
      if (role === ROLE.User || role === ROLE.Admin)
        return notificationTokens.push(roleToken);

      const isOnline = data?.isOnline;
      if (!isOnline) return;
      if (isOnline[role] && notificationToken)
        notificationTokens.push(roleToken);
    });
    return notificationTokens;
  } catch (error) {
    onError(error);
  }
};

export const notifyUserAndStaff = (order, status, user) => {
  //* Notify User
  const userToken = user?.notificationToken?.User;
  const notification = {
    title: `Your Order #${order.orderID} is "${status}"`,
    body: `${
      status === STATUS.Preparing.name
        ? `Kindly wait until your Order is being Prepared...`
        : `
    ${
      order.orderType === "HomeDelivery"
        ? "Our Delivery Boy will PickUp & deliver your order soon..."
        : order.orderType === "TakeAway"
        ? "Please Collect your Order."
        : "Thanks for waiting, your Order will be Served on your Table."
    }`
    }`,
    click_action: `${LINK.User}/main/orders/${order.orderID}`,
  };
  if (userToken) sendOrderNotification([userToken], notification);

  //* Notify Table
  if (order.orderType === "DineIn")
    getTokensByRole(ROLE.Table).then((tokens) => {
      const notification = {
        title: `Order #${order.orderID} of Table ${order.orderTable} is ${status}`,
        body: `${
          status === STATUS.Preparing.name
            ? "Kindly wait until your Order is being Prepared..."
            : "Kindly collect and serve the food to the appropriate Customer"
        }`,
        click_action: `${LINK.Table}/orders/details/${order.orderID}`,
      };
      if (tokens.length) sendOrderNotification(tokens, notification);
    });

  //* Notify DeliveryBoy
  if (order.orderType === "HomeDelivery" && status === STATUS.Prepared.name)
    getTokensByRole(ROLE.DeliveryBoy).then((tokens) => {
      const notification = {
        title: `New Order Received | ${status}`,
        body: `${user?.name}'s order is ready to be delivered | Open App to see details.`,
        click_action: `${LINK.DeliveryBoy}/orders/${order.orderID}`,
      };
      if (tokens.length) sendOrderNotification(tokens, notification);
    });
};

// https://www.perplexity.ai/search/b7cb3f8d-fbaa-4e68-8ff1-99bf337accb9?s=u